import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView
            textCenter
            column
            center
            w="100%"
            ph="7%"
            pt="10px"
            pb="10px"
            bg="rgba(0, 0, 0, 0.7)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView column center>
              <CFText color="white" style={{ fontSize: 35 }} raleway bold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView mt="12px" mb="8px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) bottom / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            row
            center
            alignCenter
            h="60px"
            w="100%"
            pv="5px"
            maxWidth="1400px"
            bg="rgba(0, 0, 0, 0.7)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView column justifyStart>
              <CFText
                color="white"
                style={{
                  fontSize: 30,
                }}
                raleway
                bold
              >
                NOW TAKING ONLINE ORDERS!
              </CFText>
            </CFView>
            <CFView pt="5px" ml="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
