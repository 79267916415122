import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutDivider, mobileAbout, mobileAboutDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            style="display: block"
            src={mobileAbout}
            w='100%'
            alt='Samurai Sushi & Teriyaki Japanese Restaurant About'
          />

        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView>
          <CFImage
            mb="-10px"
            style="display: block"
            src={about}
            w='100%'
            alt='Samurai Sushi & Teriyaki Japanese Restaurant About'
          />

        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
